import React from 'react';
import { Modal, Result, Button } from 'antd';

const index = ({ visible, closeWindow }) => {
  return (
    <Modal visible={visible} centered closable={false} footer={false}>
      <Result
        status='success'
        title='Success'
        extra={[
          <Button type='default' onClick={closeWindow}>
            ปิด
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default index;
