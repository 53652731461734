import React from "react";
import { Modal, Button } from "antd";

const index = ({ visible, onClose }) => {
  return (
    <Modal centered footer={false} closable={false} visible={visible}>
      <div className="policy">
        <div className="text-center">
          <h3>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h3>
          <h3>
            ของหน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          </h3>
          {/* <p style={{ color: "red" }}>จัดทำเมื่อวันที่ 17-06-2563</p> */}
        </div>
        <p className="content">
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ได้จัดทำนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ขึ้น
          เพื่อคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการทุกท่าน (Personal
          Information)
          ที่ติดต่อเข้ามายังเว็บไซต์ของหน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ดังนี้
        </p>
        <p>การเก็บรวบรวมข้อมูลส่วนบุคคล</p>
        <ol>
          <li>
            เพื่อความสะดวกในการให้บริการแก่ผู้ใช้บริการทุกท่านที่เข้ามาใช้บริการเว็บไซต์ของ
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            ทางเว็บไซต์จึงได้จัดเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้เช่น
            อีเมล์แอดเดรส (Email Address), ชื่อ-สกุล (Name-Surname),
            ที่อยู่หรือที่ทำงาน (Home or Work Address), เขตไปรษณีย์ (ZIP Code)
            หรือหมายเลขโทรศัพท์ (Telephone Number) เป็นต้น
          </li>
          <li>
            ในกรณีที่ท่านสมัคร (Sign Up)
            เพื่อสมัครสมาชิกหรือเพื่อใช้บริการอย่างใดอย่างหนึ่ง
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านเพิ่มเติม ได้แก่ เพศ (Sex), อายุ
            (Gender), สิ่งที่โปรดปราน/ความชอบ (Preferences/Favorites), ความสนใจ
            (Interests) หรือหมายเลขบัตรเครดิต (Credit Card Number)
            และที่อยู่ในการแจ้งค่าใช้จ่าย (Billing Address) เป็นต้น
          </li>
          <li>
            นอกจากนั้น เพื่อสำรวจความนิยมในการใช้บริการ
            อันจะเป็นประโยชน์ในการนำสถิติไปใช้ในการปรับปรุง
            คุณภาพในการให้บริการของ
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            จึงจำเป็นต้องจัดเก็บรวบรวมข้อมูลของท่านบางอย่างเพิ่มเติม
            ได้แก่หมายเลขไอพี (IP Address), ชนิดของโปรแกรมค้นผ่าน (Browser
            Type), โดเมนเนม (Domain Name), บันทึกหน้าเว็บ (web page),
            ของเว็บไซต์ที่ผู้ใช้เยี่ยมชม เวลาที่เยี่ยมชมเว็บไซต์ (Access Times)
            และเว็บไซต์ที่ผู้ใช้บริการเข้าถึงก่อนหน้านั้น (Referring Website
            Addresses)
          </li>
          <li>
            กรณีที่มีการรายงานข้อมูลเข้าสู่ระบบ ทั้งในส่วนของอาการไม่พึงประสงค์
            ผลิตภัณฑ์ต้องสงสัย และโฆษณาเกินจริง
            เพื่อนำไปใช้ในการติดตามและเฝ้าระวังความปลอดภัยด้านผลิตภัณฑ์สุขภาพ
            การพัฒนาสื่อเพื่อเผยแพร่ การขับเคลื่อนเชิงนโนบาย และการวิจัย
            ซึ่งจะมีการรายงานข้อมูลเข้ามาในระบบหลายปะเภท เช่น ข้อมูลผู้ป่วย
            (Patient profile) ข้อมูลผลิตภัณฑ์ (Product profile)
            ผลการตรวจวิเคราะห์ (Laboratory data)
            รูปภาพและวีดีโอที่เกี่ยวข้องกับการรายงาน (เช่น ผู้ป่วย อาการ
            ผลิตภัณฑ์ สื่อโฆษณา สถานที่เกิดเหตุ เป็นต้น)
            ข้อมูลที่นำเข้าระบบต้องเป็นความจริง ไม่นำเข้าข้อมูลที่เป็นเท็จ
            และข้อมูลจากผู้อื่นที่ท่านรายงานเข้าสู่ระบบต้องได้รับการอนุญาตจากเจ้าของข้อมูล
            และ/หรือ ผู้มีอำนาจดูแลของเจ้าของข้อมูลแล้วเท่านั้น
          </li>
          <li>
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            ขอแนะนำให้ท่านตรวจสอบนโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy
            Policy)
            ของเว็บไซต์อื่นที่เชื่อมโยงจากเว็บไซต์นี้เพื่อจะได้ทราบและเข้าใจว่าเว็บไซต์ดังกล่าวเก็บรวบรวม
            ใช้หรือดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านอย่างไร ทั้งนี้
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            ไม่สามารถรับรองข้อความ หรือรับรองการดำเนินการใด ๆ
            ตามที่ได้มีการประกาศไว้ในเว็บไซต์ดังกล่าวได้ และไม่ขอรับผิดชอบใด ๆ
            หากเว็บไซต์เหล่านั้นไม่ได้ปฏิบัติการหรือ ดำเนินการใด ๆ
            ตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่เว็บไซต์ดังกล่าวได้ประกาศไว้
          </li>
        </ol>
        <p>การใช้ข้อมูลส่วนบุคคล</p>
        <ol>
          <li>
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            จะใช้ข้อมูลส่วนบุคคลของท่านเพียงเท่าที่จำเป็น เช่น ชื่อ และ ที่อยู่
            เพื่อใช้ในการติดต่อ ให้บริการ ประชาสัมพันธ์หรือให้ข้อมูลขาวสารต่าง ๆ
            รวมทั้งสำรวจความคิดเห็นของท่านในกิจการหรือกิจกรรมของหน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            เท่านั้น
          </li>
          <li>
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            ขอรับรองว่าจะไม่นำข้อมูลส่วนบุคคลของท่านที่หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            เก็บรวบรวมไว้ไปขายหรือเผยแพร่ให้กับบุคคลภายนอกโดยเด็ดขาด
            เว้นแต่จะได้รับอนุญาตจากท่านเท่านั้น
          </li>
          <li>
            ในกรณีที่
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            ได้ว่าจ้างหน่วยงานอื่นเพื่อให้ดำเนินการเกี่ยวกับ
            ข้อมูลส่วนบุคคลของท่าน เช่น
            การจัดส่งพัสดุไปรษณีย์การวิเคราะห์เชิงสถิติในกิจการหรือกิจกรรมของ
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            เป็นต้น
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
            จะกำหนดให้หน่วยงานที่ได้ว่าจ้างให้ดำเนินการดังกล่าว
            เก็บรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
            และกำหนดข้อห้ามมิให้มีการนำข้อมูลส่วนบุคคลดังกล่าวไปใช้นอกเหนือจากกิจกรรมหรือ
            กิจการของ
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          </li>
        </ol>
        <p>สิทธิในการควบคุมข้อมูลส่วนบุคคลของท่าน</p>
        <p className="content">
          เพื่อประโยชน์ในการรักษาความเป็นส่วนตัวของท่าน
          ท่านมีสิทธิเลือกที่จะให้มีการใช้หรือแชร์ข้อมูลส่วน บุคคลของท่าน
          หรืออาจเลือกที่จะไม่รับข้อมูลหรือสื่อทางการตลาดใด ๆ จาก
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ก็ได้ โดยเพียงแต่ท่านกรอกความจำนงดังกล่าวเพื่อแจ้งให้
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ทราบในหน้าเว็บ https://tawaiforhealth.org/
        </p>
        <p>การรักษาความปลอดภัยสำหรับข้อมูลส่วนบุคคล</p>
        <p className="content">
          เพื่อประโยชน์ในการรักษาความลับและความปลอดภัยสำหรับข้อมูลส่วนบุคคลของท่าน
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          จึงได้กำหนดระเบียบภายในหน่วยงานเพื่อกำหนดสิทธิในการเข้าถึงหรือใช้ข้อมูล
          ส่วนบุคคลของท่าน
          และเพื่อรักษาความลับและความปลอดภัยของข้อมูลบางอย่างที่มีความสำคัญอย่างยิ่ง
          เช่น หมายเลขบัตรประชาชน เป็นต้น
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          จึงได้จัดให้มีช่องทางการสื่อสารแบบปลอดภัยสำหรับข้อมูลดังกล่าวด้วยการเข้ารหัสลับข้อมูลดังกล่าว
          เช่น จัดให้มีการใช้ Secure Socket Layer (SSL) protocol เป็นต้น
        </p>
        <p>การใช้คุกกี้ (Cookies)</p>
        <p className="content">
          “คุกกี้” คือ
          ข้อมูลที่หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ส่งไปยังโปรแกรมค้นผ่านเว็บไซต์ (Web browser) ของผู้ใช้บริการ
          และเมื่อมีการติดตั้งข้อมูลดังกล่าวไว้ในระบบของท่านแล้ว หากมีการใช้
          “คุกกี้” ก็จะทำ ให้เว็บไซต์ (ของหน่วยงาน)
          สามารถบันทึกหรือจดจำข้อมูลของผู้ใช้บริการไว้
          จนกว่าผู้ใช้บริการจะออกจากโปรแกรมค้นผ่านเว็บไซต์
          หรือจนกว่าผู้ใช้บริการจะทำการลบ “คุกกี้” นั้นเสีย หรือไม่อนุญาตให้ “
          คุกกี้” นั้น ทำงานอีกต่อไป
        </p>
        <p className="content">
          หากท่านเลือกใช้ “คุกกี้” แล้ว
          ท่านจะได้รับความสะดวกสบายในการท่องเว็บไซต์มากขึ้น เพราะ “คุกกี้”
          จะช่วยจดจำเว็บไซต์ที่ท่านแวะหรือเยี่ยมชม
          ทั้งนี้หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          จะนำข้อมูลที่“คุกกี้” ได้บันทึกหรือเก็บรวบรวมไว้
          ไปใช้ในการวิเคราะห์เชิงสถิติหรือในกิจกรรมอื่นของ
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          เพื่อปรับปรุงคุณภาพการให้บริการของ
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ต่อไป
        </p>
        <p>การปรับปรุงนโยบายการคุ้มครองข้อมูลส่วนบุคคล</p>
        <p className="content">
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          อาจทำการปรับปรุงหรือแก้ไขนโยบายการคุ้มครองข้อมูลส่วนบุคคลโดยมิได้แจ้งให้ท่านทราบล่วงหน้า
          ทั้งนี้เพื่อความเหมาะสมและมีประสิทธิภาพในการให้บริการ ดังนั้น
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          จึงขอแนะนำให้ผู้ใช้บริการอ่านนโยบายการคุ้มครองข้อมูลส่วนบุคคลทุกครั้งที่เยี่ยมชม
          หรือมีการใช้บริการจากเว็บไซต์ของ (หน่วยงาน)
        </p>
        <p>
          การปฏิบัติตามนโยบายคุ้มครองข้อมูลส่วนบุคคลและการติดต่อกับหน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
        </p>
        <p className="content">
          ในกรณีที่ท่านมีข้อสงสัย ข้อเสนอแนะ หรือข้อติชมใด ๆ
          เกี่ยวกับนโยบายการคุ้มครองข้อมูลส่วนบุคคล
          หรือการปฏิบัติตามนโยบายการคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ยินดีที่จะตอบข้อสงสัย รับฟังข้อเสนอแนะ และคำติชมทั้งหลาย
          อันจะเป็นประโยชน์ต่อการปรับปรุงการให้บริการของ
          หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ต่อไป
          โดยท่านสามารถติดต่อกับหน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา
          ตามที่อยู่ที่ปรากฏข้างล่างนี้
        </p>
        <div className="detail">
          <div>
            หน่วยวิจัยและพัฒนานวัตกรรมเพื่อผู้บริโภคด้านผลิตภัณฑ์สุขภาพและการโฆษณา{" "}
          </div>
          <div>
            ที่อยู่ : คณะเภสัชศาสตร์ มหาวิทยาลัยสงขลานครินทร์ 15 ถนนกาญจนวณิช
            อ.หาดใหญ่ จ.สงขลา 90110{" "}
          </div>
          <div>
            โทรศัพท์ :<a href="tel:081-6518303"> 081-6518303</a>
          </div>
          <div>
            Email :
            <a href="mailto:tawaiforhealth1@gmail.com">
              {" "}
              tawaiforhealth1@gmail.com
            </a>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button type="primary" onClick={onClose}>
          ปิด
        </Button>
      </div>
    </Modal>
  );
};

export default index;
