import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';

import { Form, Select, Button, message } from 'antd';
import { LiffLogin, SavaDataLiff } from '../../services/api';

const { Option } = Select;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [userId, setUserId] = useState('');

  useEffect(() => {
    const onLogin = async () => {
      const res = await LiffLogin(liff, '1656918107-PjrZmZG1');
      setUserId(res.userId);
    };
    onLogin();
  }, [liff]);

  const onSubmit = async (values) => {
    let payload = {
      userId: userId,
      data: values,
    };

    const res = await SavaDataLiff(payload);
    if (res.status === 200) {
      message.success('บันทึก');
      liffClose();
    } else {
      message.error(res.message);
    }
  };

  const liffClose = () => {
    liff.init({ liffId: '1656918107-PjrZmZG1' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'ຂໍ້ມູນຄົບຖ້ວນ',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  // const checkFreeText = () => {
  //   if (model === 2) {
  //     return product.includes(productModelList[model]?.products?.length - 1);
  //   } else {
  //     return productModelList[model]?.products[product]?.free_text;
  //   }
  // };

  let TypeDrug = [
    'ຢາແຄັບຊູນ',
    'ຢາເມັດ',
    'ຢານ້ຳ',
    'ຢາທາ',
    'ຢາສັກ',
    'ຢາຍັດ',
    'ຢາຢອດ',
    'ຢາພົ່ນ',
  ];

  const Uses = [
    'ກິນ',
    'ທາ/ພອກ/ທາສິວ',
    'ອົມ',
    'ສັກ',
    'ສູດດົມ/ພົ່່ນ',
    'ບ້ວນປາກ/ທາປາກ',
    'ສະຫົວ',
  ];

  return (
    <Layout>
      <div className='product-model'>
        <Header title='ຂໍ້​ມູນ​ການ​ນໍາ​ໃຊ້​ຜະ​ລິດ​ຕະ​ພັນ​' />
        <Form layout='vertical' onFinish={onSubmit}>
          <Form.Item
            label='ຮູບແບບຜະລິດຕະພັນ ຫຼື ຢາ'
            name='TypeDrug'
            rules={[{ required: true, message: 'Please select your data' }]}
          >
            <Select>
              {TypeDrug.map((item) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='ວິທີການໃຊ້ຢາ'
            name='Use'
            rules={[{ required: true, message: 'Please select your data' }]}
          >
            <Select>
              {Uses.map((item) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button block type='primary' htmlType='submit'>
              ຢືນຢັນ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
