import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const Index = ({ isHome }) => {
  const router = useHistory();
  return (
    <div className='app-bar'>
      <div className='body'>
        <div>
          {isHome ? (
            <div className='logo'>
              <img src={require('../../assets/images/logo.png')} alt='logo' />
            </div>
          ) : (
            <ArrowLeftOutlined
              className='icon'
              onClick={() => router.goBack()}
            />
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Index;
