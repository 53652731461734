import React, { useEffect } from 'react';

const Index = ({ current, title, subTitle }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div className='header'>
      <div className='title'>
        <p>{title}</p>
      </div>
      <div className='sub-title'>
        <div>
          <span>{subTitle}</span>
        </div>
        {current && (
          <div className='step'>
            <div className={'item active'}>1</div>
            <hr className={current === 2 ? 'active' : ''} />
            <div className={current === 2 ? 'item active' : 'item'}>2</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
