import { baseURL } from '../config';

export const Fetch = async (method = 'POST', token, path, data = {}) => {
  try {
    const url = `${baseURL}/api${path}`;
    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token !== '' ? 'Bearer ' + token : '',
      },
      method: method || 'POST',
      body: method === 'POST' ? JSON.stringify(data) : null,
    });
    const status = res.status;
    const datas = await res.json();
    return { ...datas, status: status };
  } catch (err) {
    return err;
  }
};
