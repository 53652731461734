import React from "react";
import { useHistory } from "react-router-dom";

import AppBar from "../components/AppBar";

const Index = ({ children }) => {
  const router = useHistory();
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "coloumn",
        justifyContent: "center",
        alignItems: "start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          maxWidth: "768px",
          width: "100%",
        }}
      >
        <AppBar
          isHome={router.location.pathname === "/register-2" ? false : true}
        />
        {children}
      </div>
    </div>
  );
};

export default Index;
