import React, { useState, useEffect } from 'react';

import Layout from '../../Layout';
import Header from '../../components/Header';
import Policy from '../../components/Policy';
import SucessItem from '../../components/Sucess';

import { Form, Input, Button, Select, message, Checkbox } from 'antd';

// import { list as AreaList } from '../../data/Areas';
import { list as OfficeList } from '../../data/offices';
import { Register } from '../../services/api';

const { Option } = Select;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';
  const [form] = Form.useForm();

  const [userData, setUserData] = useState();
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isRegister, setRegistered] = useState(false);

  useEffect(() => {
    const setFormValue = (value) => {
      const {
        office,
        office_additional,
        area,
        email,
        lineId,
        phone,
        officePhone,
      } = value;
      form.setFieldsValue({
        office: office,
        office_additional: office_additional,
        area: area,
        email: email,
        lineId: lineId,
        phone: phone,
        officePhone: officePhone,
      });
    };

    let userData = JSON.parse(localStorage.getItem('userData')) || null;
    let officer = JSON.parse(localStorage.getItem('officer') || null);
    officer && setFormValue(officer);
    setUserData(userData);
  }, [form]);

  const onFinish = async (value) => {
    const { userId, name, surname, province, district, area } = userData;

    let payload = {
      userId: userId,
      name: name,
      surname: surname,
      address: {
        province: province,
        district: district,
        area: area,
      },
      officer: value,
    };

    const res = await Register(payload);
    if (res.status === 200) {
      setRegistered(true);
      localStorage.setItem('officer', JSON.stringify(value));
      localStorage.setItem('isRegister', true);
    } else {
      message.warning(res.message);
    }
  };

  const closeWindow = () => {
    liff.init({ liffId: '1656918107-N6K0j0bk' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'ລົງທະບຽນ',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          message.warning(err);
          console.log('error', err);
        });
    });
  };

  return (
    <Layout>
      <SucessItem visible={isRegister} closeWindow={closeWindow} />
      <Policy visible={visible} onClose={() => setVisible(!visible)} />
      <div className='register'>
        <Header
          current={2}
          title={isRegister ? 'ແກ້ໄຂຂໍ້ມູນສະມາຊິກ' : 'ລົງທະບຽນ'}
          subTitle='ຂໍ້ມູນກ່ຽວກັບອົງການ'
        />
        <Form form={form} layout='vertical' onFinish={onFinish}>
          <Form.Item
            label='ບ່ອນເຮັດວຽກ'
            name='office'
            rules={[{ required: true, message: 'please select your office' }]}
          >
            <Select>
              {OfficeList.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) =>
              getFieldValue('office') === 'ອື່ນໆ' && (
                <Form.Item
                  label='ເພີ່ມເຕີມ (additional office)'
                  name='office_additional'
                >
                  <Input.TextArea />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            label='ໂທລະສັບມືຖື'
            name='phone'
            rules={[
              {
                required: true,
                pattern: /^[0-9]*$/,
                message: 'please input your phone',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ເບີໂທບ່ອນເຮັດວຽກ'
            name='officePhone'
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: 'please input your office phone',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Line ID'
            name='lineId'
            rules={[
              {
                required: true,
                message: 'please input your line ID',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='ອີເມວ'
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'please input your email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className='form-item'>
            <Checkbox
              className='mr-5'
              onChange={() => setDisabled(!disabled)}
            />
            ຍອມຮັບການນຳໃຊ້
            <span className='terms' onClick={() => setVisible(!visible)}>
              ບໍລິການ
            </span>
          </div>
          <Form.Item>
            <Button type='primary' block htmlType='submit' disabled={disabled}>
              ຢືນຢັນ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
