import React, { useState } from 'react';
import DataListInput from 'react-datalist-input';
import _questionables from '../../data/questionables.json';

import Layout from '../../Layout';
import Header from '../../components/Header';
import { Form, Button, message } from 'antd';

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [form] = Form.useForm();
  const [product, setProduct] = useState('');

  const liffClose = (text) => {
    liff.init({ liffId: '1656918107-WKNxMxk3' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: text,
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          message.error(err);
        });
    });
  };

  return (
    <Layout>
      <div style={{ padding: 10, minHeight: '100vh' }}>
        <Header title='ຊື່​ຜະ​ລິດ​ຕະ​ພັນ​ແລະ​ຢາ​' />
        <Form form={form} layout='vertical'>
          <Form.Item name='product'>
            <DataListInput
              className='ant-input'
              itemClassName='ant-select'
              placeholder='Select an option from the drop down menu...'
              items={_questionables.map((item, index) => ({
                label: item.label,
                key: item.label + index,
                ...item,
              }))}
              onSelect={(e) => setProduct(e.label)}
              onInput={(e) => setProduct(e)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              block
              onClick={() => liffClose(product)}
              disabled={!product}
            >
              ຢືນຢັນ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
