import { Fetch } from './Fetch';

export const LiffLogin = async (liff, id) => {
  let profile = '';

  await liff.init({ liffId: id });
  if (liff.isLoggedIn()) {
    profile = await liff.getProfile();
  } else {
    liff.login();
  }
  return profile;
};

export const Register = async (data, token = '') => {
  const res = await Fetch('POST', token, `/liff/customer/register`, data);
  return res;
};

export const SavaDataLiff = async (data, token = '') => {
  const res = await Fetch('POST', token, `/liff/save/data`, data);
  return res;
};

export const GetDataLiff = async (data, token = '') => {
  const res = await Fetch('POST', token, `/liff/get/data`, data);
  return res;
};
