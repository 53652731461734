import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Register1 from './views/Register/1';
import Register2 from './views/Register/2';
import ProductModel from './views/ProductModel';
import QuestionableProduct from './views/QuestionableProduct';

import './App.css';
import './assets/scss/style.css';
import 'antd/dist/antd.less';

function App() {
  return (
    <React.Fragment>
      <Router forceRefresh>
        <Switch>
          <Route
            exact
            path='/register-1'
            name='register 1'
            component={Register1}
          />
          <Route
            exact
            path='/register-2'
            name='register-2'
            component={Register2}
          />
          <Route
            exact
            path='/product-model'
            name='Product Model'
            component={ProductModel}
          />
          <Route
            exact
            path='/questionable-product'
            name='Questionable Product'
            component={QuestionableProduct}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
