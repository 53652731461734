import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import Layout from '../../Layout';
import Header from '../../components/Header';
// import AddressForm from '../../components/Form/Address';

import { Form, Input, Button } from 'antd';

// import provinces from '../../data/address/provinces.json';
// import districts from '../../data/address/districts.json';
// import subDistricts from '../../data/address/subDistricts.json';
// import zipcodes from '../../data/address/zipcodes.json';
import { LiffLogin } from '../../services/api';

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';
  const router = useHistory();

  const [userId, setUserId] = useState('');
  // const [optionProvinces] = useState(provinces);
  // const [optionDistricts, setOptionDistricts] = useState(districts);
  // const [optionSubDistricts, setOptionSubDistricts] = useState(subDistricts);

  const [name, setName] = useState('');
  const [surname, setSurName] = useState('');
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  // const [subdistrict, setSubDistrict] = useState();
  // const [zipCode, setZipCode] = useState('');
  const [area, setArea] = useState('');
  const [isRegister] = useState(false);

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      const { name, surname, area, province, district } = userData;
      setName(name);
      setSurName(surname);
      setArea(area);
      setProvince(province);
      setDistrict(district);
    }
    const getProfile = async () => {
      const res = await LiffLogin(liff, '1656918107-N6K0j0bk');
      console.log('get profile', res);
      setUserId(res.userId);
    };
    getProfile();
  }, [liff]);

  // const onChangeAddress = (value, key) => {
  //   // console.log(value, key);
  //   switch (key) {
  //     case 'province':
  //       setProvince(value);
  //       setOptionDistricts(
  //         districts.filter((item) => item.PROVINCE_ID === +value)
  //       );
  //       setDistrict('');
  //       break;
  //     case 'district':
  //       setDistrict(value);
  //       setOptionSubDistricts(
  //         subDistricts.filter((item) => item.DISTRICT_ID === +value)
  //       );
  //       setSubDistrict('');
  //       break;
  //     case 'sub_district':
  //       setSubDistrict(value);
  //       setZipCode(
  //         zipcodes.filter(
  //           (item) => item.SUB_DISTRICT_ID === value.toString()
  //         )[0].ZIPCODE
  //       );
  //       break;
  //     case 'area':
  //       setArea(value);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const onSubmit = () => {
    let data = {
      userId: userId,
      name: name,
      surname: surname,
      province: province || '',
      district: district || '',
      // subdistrict: subdistrict || '',
      // zipCode: zipCode || '',
      area: area,
    };
    localStorage.setItem('userData', JSON.stringify(data));
    router.push('/register-2');
  };

  return (
    <Layout>
      <div className='register'>
        <Header
          current={1}
          title={isRegister ? 'แก้ใขข้อมูลสมาชิก' : 'ສະໝັກສະມາຊິກ'}
          subTitle='ຂໍ້ມູນສ່ວນຕົວ'
        />
        <Form layout='vertical' name='user'>
          <Form.Item label='ຊື່'>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Form.Item>
          <Form.Item label='ນາມສະກຸນ'>
            <Input
              value={surname}
              onChange={(e) => setSurName(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='ທີ່ຢູ່ປະຈຸບັນ'>
            <Input.TextArea
              value={area}
              onChange={(e) => setArea(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='ເມືອງ'>
            <Input
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='ແຂວງ'>
            <Input
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' block onClick={() => onSubmit()}>
              ຕໍ່ໄປ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
